<template>
  <section class="section">
    <base-header>
      <template v-slot:header-table
        ><h1>{{ name }}</h1></template
      >
      <template v-slot:item-slot>
        <div class="breadcrumb-item">Admin</div>
        <div class="breadcrumb-item">{{ name }}</div>
      </template>
    </base-header>

    <div class="section-body">
      <base-title></base-title>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4>{{ name }}</h4>
            </div>
            <form @submit.prevent="adminInsert">
              <div class="card-body">
                <div class="form-group">
                  <label>User</label>
                  <model-list-select
                    :list="admins"
                    v-model="form.admin_id"
                    option-value="id"
                    option-text="username"
                    placeholder="Select Admin"
                  >
                  </model-list-select>
                  <small class="text-danger" v-if="errors.admin_id">{{
                    errors.admin_id[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Payment Type</label>
                  <model-list-select
                    :list="payment_types"
                    v-model="form.payroll_type"
                    option-value="name"
                    option-text="name"
                    placeholder="Select Payment Type"
                  >
                  </model-list-select>
                  <small class="text-danger" v-if="errors.admin_id">{{
                    errors.admin_id[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Account Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.account_name"
                  />
                  <small class="text-danger" v-if="errors.account_name">{{
                    errors.account_name[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Account Bank Name</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.account_bank_name"
                  />
                  <small class="text-danger" v-if="errors.account_bank_name">{{
                    errors.account_bank_name[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>Account Bank Number</label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="form.account_number"
                  />
                  <small class="text-danger" v-if="errors.account_number">{{
                    errors.account_number[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>UMK</label>
                  <money v-model="form.umk" class="form-control"></money>
                  <small class="text-danger" v-if="errors.umk">{{
                    errors.umk[0]
                  }}</small>
                </div>
                <div class="form-group">
                  <label>BPJS</label>
                  <money v-model="form.bpjs" class="form-control"></money>
                  <small class="text-danger" v-if="errors.bpjs">{{
                    errors.bpjs[0]
                  }}</small>
                </div>
              </div>
              <div class="card-footer text-right">
                <button
                  class="btn btn-primary mr-1"
                  :disabled="isSubmitting"
                  v-if="form.password == form.password_confirmation"
                  type="submit"
                >
                  Submit
                </button>
                <button class="btn btn-secondary" type="reset">Reset</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { apiGet, apiPost } from "../../../services/api";

export default {
  name: "FormAdmin",
  data() {
    return {
      name: "Form User Payroll",
      form: {
        admin_id: "",
        account_name: "",
        account_bank_name: "",
        account_number: "",
        umk: "",
        bpjs: "",
        payroll_type: "",
      },
      admins: [],
      payment_types: [],
      errors: {},
      role: [],
      isSubmitting: false,
    };
  },
  mounted() {
    this.getAdmin();
  },
  methods: {
    adminInsert() {
      this.isSubmitting = true;
      apiPost("admin_payroll_info/store", this.form)
        .then(() => {
          this.$noty.success("Your Admin Payroll has been created!");
          this.$router.push({ name: "Admin" });
        })
        .catch((err) => {
          this.errors = err.response.data;
          this.isSubmitting = false;
        });
    },
    getAdmin() {
      apiGet("admin")
        .then((res) => {
          this.admins = res.data.data;
          apiGet("payment_type")
            .then((res) => {
              this.payment_types = res.data.data;
            })
            .catch((err) => console.log(err));
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style>
.btn-primary {
  color: #fff;
}
</style>
